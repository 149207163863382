import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { useSnackbar } from 'baseui/snackbar';
import { DisplayMedium, LabelSmall, ParagraphLarge } from "baseui/typography";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Spacer from '../components/Spacer';
import { ROUTES } from '../models/types';
import UserService, { type LoginFields } from '../services/api/user';

type FormFieldError = {
    field: string;
    message: string;
}

export type ForgotPasswordFields = Pick<LoginFields, 'username'>

const Login = () => {
    const [fields, setFields] = useState<ForgotPasswordFields>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState<FormFieldError[]>([]);
    const { enqueue } = useSnackbar();

    const isError = (field: string) => {
        if (errors.length <= 0 || typeof errors !== 'object') return false;
        return errors.some((e: FormFieldError) => e.field === field);
    }

    const getErrorMessage = (field: string) => {
        const error = errors.find((e: FormFieldError) => e.field === field);
        return error ? error.message : '';
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        setFields((p: ForgotPasswordFields) => ({ ...p, [name]: value }));
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors([]);
        try {
            const response = await UserService.resetPassword({
                username: fields.username
            });
            console.log('response', response)
            setIsLoading(false);
            setIsSubmitted(true);
        } catch (err) {
            if ('data' in (err as any)) setErrors((err as any).data as FormFieldError[]);
            else {
                // TODO: Send event to sentry
                enqueue({
                    message: 'Something went wrong. Please try again later.',
                }, 5000)
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Block
            width="100vw"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Block margin="0 auto" padding="0 1rem" width="320px">
                <DisplayMedium style={{ textAlign: 'center' }}>Forgot your password?</DisplayMedium>
                <Spacer size="14px" />
                <ParagraphLarge style={{ textAlign: 'center' }}>No worries! Enter your email below and we'll send you a link to reset your password.</ParagraphLarge>
                <Spacer size="14px" />
                <form onSubmit={handleSubmit}>
                    <FormControl
                        htmlFor="username"
                        label={() => "Email"}
                        disabled={isLoading}
                        error={isError('username') && getErrorMessage('username')}
                    >
                        <Input
                            name="username"
                            id="username"
                            value={fields?.username ?? ""}
                            autoComplete="email"
                            placeholder="you@example.com"
                            error={isError('username')}
                            onChange={handleInputChange}
                            disabled={isSubmitted}
                        />
                    </FormControl>
                    <Button isLoading={isLoading} disabled={isSubmitted} type="submit" overrides={{ Root: { style: { width: '100%' } } }}>
                        {isSubmitted ? 'Link sent' : 'Send link to reset'}
                    </Button>
                </form>
                <Spacer size="12px" />
                <LabelSmall as={Link} to={ROUTES.LOGIN} style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>Back to sign in</LabelSmall>
            </Block>
        </Block>
    )
}

export default Login