import { BaseProvider, createTheme, lightThemePrimitives, useStyletron } from 'baseui';
import { colors } from 'baseui/tokens';
import { SnackbarProvider } from 'baseui/snackbar';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import AuthenticationMiddleware from './components/AuthenticationMiddleware';
import ProtectedRoute from './components/ProtectedRoute';
import { ROUTES } from './models/types';
import Root from './pages';
import Login from './pages/login';
import ForgotPassword from './pages/forgot-password';
import Account from './pages/account';
import Navigation from './components/UI/Navigation';
import { Block } from 'baseui/block';
import Pool from './pages/pool';
import Logout from './pages/logout';

const engine = new Styletron();

const myThemePrimitives = {
  ...lightThemePrimitives,
  primary: colors.blue400,
  primaryFontFamily: 'Inter, sans-serif'
}

const overrides = {
  colors: {
    buttonPrimaryHover: colors.blue500,
  },
};

const theme = createTheme(myThemePrimitives, overrides);

const App = () => {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <Application />
      </BaseProvider>
    </StyletronProvider>
  )
}

const Application = () => {
  return (
    <AuthenticationMiddleware>
      <SnackbarProvider>
        <BrowserRouter>
          <Routes>
            <Route path={ROUTES.ROOT} element={<Root />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route element={<ProtectedRouteWithNavigation />}>
              <Route path={ROUTES.POOL} element={<Pool />} />
              <Route path={ROUTES.ACCOUNT} element={<Account />} />
              <Route path={ROUTES.LOGOUT} element={<Logout />} />
            </Route>
            <Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </AuthenticationMiddleware >
  )
}

const ProtectedRouteWithNavigation = () => {
  const [css] = useStyletron();
  return (
    <ProtectedRoute>
      <Block
        flexDirection={['column', 'column', 'column', 'row']}
        className={css({
          display: 'flex',
          minHeight: '100vh'
        })}
      >
        <Navigation />
        <Outlet />
      </Block>
    </ProtectedRoute>
  )
}

export default App