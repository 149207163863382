import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { HeadingLarge } from 'baseui/typography';
import { ReactNode, useEffect, useState } from 'react';
import { navigationList } from '../../components/UI/Navigation';
import PoolService, { Media, MediaProps } from '../../services/api/pool';

const Pool = () => {
    const [css, theme] = useStyletron();
    const [media, setMedia] = useState<Media[] | null>(null);
    const accountNavigation = navigationList.find((el) => el.href === '/pool');

    const mediaQueryProps: MediaProps = {
        skip: 0,
        top: 50,
        include: ['versions', 'locations', 'campaigns', 'types', 'tags', 'screengroups', 'timeranges'],
        orderby: ['createdAt desc']
    }

    const fetchMedia = async () => {
        try {
            const response = await PoolService.getMedia(mediaQueryProps);
            if (response.data) setMedia(response.data);
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (!media) fetchMedia();
    }, [media])

    console.log('media', media)

    return (
        <Layout>
            <Block
                className={css({
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.sizing.scale1000
                })}
            >
                <HeadingLarge>{accountNavigation?.label}</HeadingLarge>
                <div className={css({ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: theme.sizing.scale400 })}>
                    {media && Array.isArray(media) && media.length > 0 && media.map((media) => {
                        const firstMediaVersionFiles = media.mediaVersions?.[0]?.files;
                        const file = firstMediaVersionFiles[0];
                        const isVideoFile = file.mime_type.includes('video');

                        return (
                            <div>
                                {isVideoFile ? (
                                    <div>
                                        <video width="320" height="240" controls>
                                            <source src={file.url} type={file.mime_type} />
                                        </video>
                                    </div>
                                ) : <img src={file.url} alt={media.name} className={css({ width: '200px', height: '200px', objectFit: 'cover' })} />}

                                <p>{media.name}</p>
                            </div>
                        )
                    })}
                </div>
            </Block>
        </Layout>
    )
}

const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <Block
            width="100%"
            paddingLeft={['scale200', 'scale400', 'scale600', 'scale1200']}
            paddingRight={['scale200', 'scale400', 'scale600', 'scale1200']}
            paddingTop={['scale1000']}
        >
            {children}
        </Block>
    )
}

export default Pool