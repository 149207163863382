import { useEffect } from 'react';
import UserService from '../services/api/user';

const Logout = () => {

    useEffect(() => {
        (async () => UserService.revokeSession())();
    }, [])

    return null
}

export default Logout