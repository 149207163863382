import { useStyletron } from 'baseui';
import { Avatar } from "baseui/avatar";
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { ArrowLeft } from 'baseui/icon';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { useAtomValue } from 'jotai';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '../../../shared/assets/icons/CloseIcon';
import { baseUserAtom } from '../../../store/userStore';
import OrganisationSwitcher from './OrganisationSwitcher';

export type NavigationItem = {
    label: string;
    href: string;
    description?: string;
    icon?: ReactNode;
    items?: NavigationItem[];
}

export const navigationList: NavigationItem[] = [{
    label: 'Playlist Content Pool',
    href: '/pool',
}, {
    label: 'Campaigns',
    href: '/campaigns',
}, {
    label: 'Content Assets',
    href: '/assets',
}, {
    label: 'Playlists',
    href: '/playlists',
}, {
    label: 'Screens',
    href: '/screens',
}, {
    label: 'Account settings',
    href: '/account',
    description: 'Users, locations etc',
    icon: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9334 27.3333L10.2667 23.1333C9.84447 22.9777 9.40002 22.7666 8.93335 22.5C8.46669 22.2333 8.05558 21.9555 7.70002 21.6666L3.76669 23.4666L0.666687 18L4.26669 15.3666C4.22224 15.1666 4.19446 14.9388 4.18335 14.6833C4.17224 14.4277 4.16669 14.2 4.16669 14C4.16669 13.8 4.17224 13.5722 4.18335 13.3166C4.19446 13.0611 4.22224 12.8333 4.26669 12.6333L0.666687 9.99996L3.76669 4.53329L7.70002 6.33329C8.05558 6.0444 8.46669 5.76663 8.93335 5.49996C9.40002 5.23329 9.84447 5.03329 10.2667 4.89996L10.9334 0.666626H17.0667L17.7334 4.86663C18.1556 5.02218 18.6056 5.22774 19.0834 5.48329C19.5611 5.73885 19.9667 6.02218 20.3 6.33329L24.2334 4.53329L27.3334 9.99996L23.7334 12.5666C23.7778 12.7888 23.8056 13.0277 23.8167 13.2833C23.8278 13.5388 23.8334 13.7777 23.8334 14C23.8334 14.2222 23.8278 14.4555 23.8167 14.7C23.8056 14.9444 23.7778 15.1777 23.7334 15.4L27.3334 18L24.2334 23.4666L20.3 21.6666C19.9445 21.9555 19.5389 22.2388 19.0834 22.5166C18.6278 22.7944 18.1778 23 17.7334 23.1333L17.0667 27.3333H10.9334ZM14 18.3333C15.2 18.3333 16.2222 17.9111 17.0667 17.0666C17.9111 16.2222 18.3334 15.2 18.3334 14C18.3334 12.8 17.9111 11.7777 17.0667 10.9333C16.2222 10.0888 15.2 9.66663 14 9.66663C12.8 9.66663 11.7778 10.0888 10.9334 10.9333C10.0889 11.7777 9.66669 12.8 9.66669 14C9.66669 15.2 10.0889 16.2222 10.9334 17.0666C11.7778 17.9111 12.8 18.3333 14 18.3333Z" fill="#545454" />
        </svg>
    ),
    items: [{
        label: 'Team members',
        href: '/account#team-members',
        description: "Add & Manage users",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7c7c7c" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><path d="M16.719 19.752l-.64-5.124A3 3 0 0 0 13.101 12h-2.204a3 3 0 0 0-2.976 2.628l-.641 5.124A2 2 0 0 0 9.266 22h5.468a2 2 0 0 0 1.985-2.248z" /><circle cx="12" cy="5" r="3" /><circle cx="4" cy="9" r="2" /><circle cx="20" cy="9" r="2" /><path d="M4 14h-.306a2 2 0 0 0-1.973 1.671l-.333 2A2 2 0 0 0 3.361 20H7" /><path d="M20 14h.306a2 2 0 0 1 1.973 1.671l.333 2A2 2 0 0 1 20.639 20H17" /></svg>
        )
    }, {
        label: 'Locations',
        href: '/account#locations',
        description: "Floor plans, rooms, timezones, proxies etc",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7c7c7c" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" style={{ minWidth: 24 }}><path d="M8.368 4.79l-2.736-.913A2 2 0 0 0 3 5.775v11.783a2 2 0 0 0 1.368 1.898l4 1.333a2 2 0 0 0 1.264 0l4.736-1.578a2 2 0 0 1 1.264 0l2.736.912A2 2 0 0 0 21 18.224V6.442a2 2 0 0 0-1.367-1.898l-4-1.333a2 2 0 0 0-1.265 0L9.631 4.789a2 2 0 0 1-1.264 0z" /><path d="M9 5v16" /><path d="M15 3v16" /></svg>
        )
    }, {
        label: 'Preferences',
        href: '/account#preferences',
        description: "Default content duration etc",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7c7c7c" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><path d="M3 5h4m14 0H11m-8 7h12m6 0h-2M3 19h2m16 0H9" /><circle cx="9" cy="5" r="2" /><circle cx="17" cy="12" r="2" /><circle cx="7" cy="19" r="2" /></svg>
        )
    }, {
        label: 'Integrations',
        href: '/account#integrations',
        description: "Add & Manage integrations",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7c7c7c" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><path d="M9.3 17.85a3.15 3.15 0 1 1-3.15-3.15h11.7a3.15 3.15 0 1 1-3.15 3.15V6.15a3.15 3.15 0 1 1 3.15 3.15H6.15A3.15 3.15 0 1 1 9.3 6.15v11.7z" /></svg>
        )
    }, {
        label: 'Plans & Billing Info',
        href: '/account#plans',
        description: "Subscriptions, invoices, payment methods etc",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7c7c7c" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" style={{ minWidth: 24 }}><path d="M5 21h12a4 4 0 0 0 4-4V5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v13c0 1.657-.343 3-2 3z" /><path d="M3 10a2 2 0 0 1 2-2h2v10.5c0 1.38-.62 2.5-2 2.5s-2-1.12-2-2.5V10z" /><circle cx="12" cy="8" r="1" /><path d="M11 14h6" /><path d="M11 17h3" /></svg>
        )
    }]
}, {
    label: 'Personal settings',
    href: '/account#personal',
    description: 'Password, email etc',
    icon: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.40002 21.5C6.80002 20.6111 8.18335 19.9388 9.55002 19.4833C10.9167 19.0277 12.4 18.8 14 18.8C15.6 18.8 17.0889 19.0277 18.4667 19.4833C19.8445 19.9388 21.2334 20.6111 22.6334 21.5C23.6111 20.3 24.3056 19.0888 24.7167 17.8666C25.1278 16.6444 25.3334 15.3555 25.3334 14C25.3334 10.7777 24.25 8.08329 22.0834 5.91663C19.9167 3.74996 17.2222 2.66663 14 2.66663C10.7778 2.66663 8.08335 3.74996 5.91669 5.91663C3.75002 8.08329 2.66669 10.7777 2.66669 14C2.66669 15.3555 2.8778 16.6444 3.30002 17.8666C3.72224 19.0888 4.42224 20.3 5.40002 21.5ZM14 15C12.7111 15 11.6278 14.5611 10.75 13.6833C9.87224 12.8055 9.43335 11.7222 9.43335 10.4333C9.43335 9.1444 9.87224 8.06107 10.75 7.18329C11.6278 6.30551 12.7111 5.86663 14 5.86663C15.2889 5.86663 16.3722 6.30551 17.25 7.18329C18.1278 8.06107 18.5667 9.1444 18.5667 10.4333C18.5667 11.7222 18.1278 12.8055 17.25 13.6833C16.3722 14.5611 15.2889 15 14 15ZM14 27.3333C12.1334 27.3333 10.3889 26.9833 8.76669 26.2833C7.14446 25.5833 5.73335 24.6277 4.53335 23.4166C3.33335 22.2055 2.38891 20.7888 1.70002 19.1666C1.01113 17.5444 0.666687 15.8111 0.666687 13.9666C0.666687 12.1444 1.01669 10.4222 1.71669 8.79996C2.41669 7.17774 3.37224 5.76663 4.58335 4.56663C5.79446 3.36663 7.21113 2.41663 8.83335 1.71663C10.4556 1.01663 12.1889 0.666626 14.0334 0.666626C15.8556 0.666626 17.5778 1.01663 19.2 1.71663C20.8222 2.41663 22.2334 3.36663 23.4334 4.56663C24.6334 5.76663 25.5834 7.17774 26.2834 8.79996C26.9834 10.4222 27.3334 12.1555 27.3334 14C27.3334 15.8222 26.9834 17.5444 26.2834 19.1666C25.5834 20.7888 24.6334 22.2055 23.4334 23.4166C22.2334 24.6277 20.8222 25.5833 19.2 26.2833C17.5778 26.9833 15.8445 27.3333 14 27.3333Z" fill="#545454" />
        </svg>
    ),
    items: [{
        label: 'Change password',
        href: '/account#change-password',
        description: "Change your password",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7c7c7c" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="10" width="18" height="12" rx="2" /><path d="M6 6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v4H6V6z" /></svg>
        )
    }]
}, {
    label: 'Support FAQ',
    href: '/support',
}, {
    label: 'Log out',
    href: '/logout',
}];

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [css, theme] = useStyletron();

    const handleToggleOpen = () => {
        setIsOpen(p => !p);
    }

    return (
        <>
            <Block
                as="nav"
                position={['fixed', 'fixed', 'fixed', 'relative']}
                display={isOpen ? 'flex' : ['none', 'none', 'none', 'flex']}
                minWidth={['100%', '100%', '100%', '350px']}
                minHeight={['100%', '100%', '100%', 'auto']}
                className={css({
                    flexDirection: 'column',
                    gap: theme.sizing.scale600,
                    backgroundColor: '#141414',
                    color: theme.colors.white,
                    paddingTop: theme.sizing.scale800,
                    paddingBottom: theme.sizing.scale800,
                })}
            >
                <MainNavigationList toggleMenu={handleToggleOpen} />
            </Block>
            <Block display={['block', 'block', 'block', 'none']}>
                <Button onClick={handleToggleOpen}>Open menu</Button>
            </Block>
        </>
    )
}

const MainNavigationList = ({ toggleMenu }: { toggleMenu: () => void }) => {
    const [css, theme] = useStyletron();
    const user = useAtomValue(baseUserAtom);
    const userName = `${user?.first_name} ${user?.last_name}`
    return (
        <>
            {Boolean(user) && (
                <div className={css({
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: theme.sizing.scale600,
                    paddingLeft: theme.sizing.scale800,
                    paddingRight: theme.sizing.scale800,
                })}>
                    <Avatar name={userName} size="scale1200" overrides={{ Root: { style: { minWidth: '48px' } } }} />
                    <Block
                        maxWidth={[/* '200px', '200px', '200px',  */'100%']}
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            overflowWrap: 'break-word'
                        })}>
                        <LabelMedium color="white">{userName}</LabelMedium>
                        <LabelSmall color="contentTertiary">{user?.email}</LabelSmall>
                    </Block>
                    <Block position="absolute" top="1rem" right="1rem" display={['block', 'block', 'block', 'none']}>
                        <Button onClick={toggleMenu} kind={KIND.tertiary} overrides={{ BaseButton: { style: { ':hover': { background: theme.colors.backgroundOverlayDark }, ':active': { background: theme.colors.backgroundOverlayDark } } } }}>
                            <CloseIcon fill="white" />
                        </Button>
                    </Block>
                </div>
            )}
            <div className={css({ width: '100%', height: '1px', background: theme.colors.backgroundInverseSecondary })} />
            <div className={css({ paddingLeft: theme.sizing.scale800, paddingRight: theme.sizing.scale800 })}>
                <OrganisationSwitcher />
            </div>
            <div className={css({ width: '100%', height: '1px', background: theme.colors.backgroundInverseSecondary })} />
            <NavigationList list={navigationList} />
        </>
    )
}

const NavigationList = ({ list }: { list: NavigationItem[] }) => {
    const [, theme] = useStyletron();
    const baseItem = useMemo(() => ({ ...list[0], items: list }), [list]);
    const [currentActiveItem, setCurrentActiveItem] = useState<NavigationItem | null>(baseItem);

    const toggleActiveItem = useCallback((item: NavigationItem) => {
        if (currentActiveItem !== item) setCurrentActiveItem({ ...item, ...item.icon && { icon: <ArrowLeft size={24} color="#141414" style={{ background: 'white', borderRadius: '4px' }} />, description: '' } })
        else setCurrentActiveItem(baseItem)
    }, [currentActiveItem, baseItem]);

    return (
        <Block as="ol" display="flex" flexDirection="column" margin="0" paddingLeft={theme.sizing.scale800} paddingRight={theme.sizing.scale800}>
            {currentActiveItem != null && JSON.stringify(currentActiveItem) !== JSON.stringify(baseItem) && <ListItem item={currentActiveItem} onClick={() => toggleActiveItem(baseItem)} />}
            {currentActiveItem?.items?.map((item, index) => {
                const hasChildren = Boolean(item.items) && Array.isArray(item.items);
                return <ListItem key={index} item={item} {...hasChildren && { onClick: (item) => toggleActiveItem(item) }} />
            })}
        </Block>
    )
}

type ListItemProps = {
    item: NavigationItem;
    onClick?: (item: NavigationItem) => void;
}

export const ListItem = ({ item, onClick }: ListItemProps) => {
    const [css, theme] = useStyletron();

    const hasChildren = Boolean(item.items) && Array.isArray(item.items);
    return (
        <li className={css({ listStyle: 'none' })}>
            <Block
                as={!hasChildren ? Link : Button}
                to={item.href}
                {...hasChildren && { kind: KIND.tertiary, overrides: { Block: { style: { background: 'transparent', ':hover': { background: theme.colors.backgroundOverlayDark }, ':active': { background: theme.colors.backgroundOverlayDark } } } } }}
                onClick={() => hasChildren && typeof onClick !== 'undefined' && onClick(item)}
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: `${theme.sizing.scale300} !important`,
                    ...theme.typography.LabelMedium,
                    gap: theme.sizing.scale600,
                    textDecoration: 'none',
                    width: '100%',
                    color: 'inherit'
                })}>
                {Boolean(item.icon) && item.icon}
                <div className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.sizing.scale100,
                    textAlign: 'left'
                })}>
                    <span className={css({
                        ...theme.typography.LabelMedium
                    })}>{item.label}</span>
                    {Boolean(item.description) && <span className={css({
                        color: theme.colors.contentTertiary,
                        ...theme.typography.LabelSmall,
                    })}>{item.description}</span>}
                </div>
            </Block>
        </li>
    )
}



export default Navigation