import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { Button } from 'baseui/button';
import { ArrowDown, Check } from 'baseui/icon';
import { StyledLink } from 'baseui/link';
import { StatefulPopover } from 'baseui/popover';
import { LabelMedium } from 'baseui/typography';
import { ReactNode, useEffect, useState } from 'react';
import UserService from '../../../services/api/user';

type Organisation = {
    id: number,
    name: string,
}

const OrganisationSwitcher = () => {
    const [css, theme] = useStyletron();
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [activeOrganisation, setActiveOrganisation] = useState<Organisation | null>(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await UserService.getActiveClients();
                setOrganisations(response);
                if (Array.isArray(response)) setActiveOrganisation(response[0]);
            } catch (err) {
                console.log('error', err)
            }
        })()
    }, []);

    if (organisations.length <= 0 || !activeOrganisation) return null;

    const handleSwitchClient = async (org: Organisation) => {
        try {
            const response = await UserService.setActiveClient(org.id);
            if (response) setActiveOrganisation(org);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <StatefulPopover
            content={
                <ol className={css({ margin: 0, padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' })}>
                    {organisations.map((org, idx) => {
                        return (
                            <Button
                                key={idx}
                                kind="secondary"
                                endEnhancer={<Check size={24} color={activeOrganisation === org ? theme.colors.eatsGreen400 : 'transparent'} />}
                                overrides={{ BaseButton: { style: { color: theme.colors.black } } }}
                                onClick={() => handleSwitchClient(org)}
                            >
                                <Org name={org.name} icon={null} />
                            </Button>
                        )
                    })}
                </ol>
            }
            accessibilityType={'tooltip'}
        >
            <StyledLink href="javascript:void(0);" tabIndex={0} style={{ textDecoration: 'none', color: '#FFF' }}>
                <Org name={activeOrganisation.name} icon={<ArrowDown size={24} color="white" />} />
            </StyledLink>
        </StatefulPopover>
    )
}

const Org = ({ name, icon }: { name: string, icon: ReactNode }) => {
    const [css, theme] = useStyletron();
    return (
        <div className={css({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.sizing.scale600,
            color: 'inherit'
        })}>
            <Avatar name={name} size="scale1200" overrides={{ Root: { style: { minWidth: '48px', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' } } }} />
            <div className={css({
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                gap: 'scale200'
            })}>
                <LabelMedium color="inherit">{name}</LabelMedium>
                {icon}
            </div>
        </div>
    )
}

export default OrganisationSwitcher